import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Toggle" component="toggle" variation="toggle" hasReactVersion hasVueVersion="toggle--default" hasAngularVersion="?path=/story/toggle--basic" codepen="QPNddd" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small toggle" component="toggle" variation="toggle--small" hasReactVersion hasVueVersion="toggle--default&knob-small=true" hasAngularVersion="?path=/story/toggle--basic&knob-disabled=&knob-checked=&knob-size=sm" codepen="XQdppE" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="toggle" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      